<template>
  <div class="recruitmentlist">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="职位详情" />
      </van-sticky>
    </div> -->
    <!-- 职位详情 -->
    <div class="limls">
      <!-- 名称 -->
      <div class="limlstitle">
        <span>{{ list.Title }}</span>
        <span>{{ list.Salary }}</span>
      </div>
      <!-- 地址 年限 学历 -->
      <div class="limlsone">
        <span
          ><i class="iconimg"
            ><img src="../../assets/icon_dizhi.png" alt=""
          /></i>
          工作地点:{{ list.WorkAddrName }}</span
        >
      </div>
      <div class="limlsone">
        <span>
          <i class="iconimg"
            ><img src="../../assets/icon_hangye.png" alt=""
          /></i>
          工作经验{{ list.WorkYear }}年
          <i class="iconimg" style="padding-left: 10px"
            ><img src="../../assets/icon_xveli.png" alt=""
          /></i>
          {{ list.EduName }}
          <i class="iconimg" style="padding-left: 10px"
            ><img src="../../assets/icon_name.png" alt=""
          /></i>
          招{{ list.RecruitNum }}人
        </span>
      </div>
    </div>
    <!-- 公司 -->
    <div class="limlsthree">
      <div style="" @click="xiangqing(list.EId)">{{ list.EName }}</div>
      <!-- <div>职位福利</div> -->
      <!-- <span>更新于07.01日</span> -->
    </div>
    <!-- 技能要求 -->
    <div class="limlsfour">
      <div>技能要求</div>
      <div>
        {{ list.Skill }}
      </div>
    </div>
    <!-- 职位描述 -->
    <div class="limlsfour">
      <div>职位描述</div>
      <div>
        {{ list.Describe }}
      </div>
    </div>
    <!-- 任职要求 -->
    <div class="limlsfour" style="padding-bottom: 80px">
      <div>任职要求</div>
      <div>
        {{ list.JobRequire }}
      </div>
    </div>
    <!-- 申请岗位按钮 -->
    <div class="button">
      <van-button v-if="shenqingshow" round type="info" @click="applyfrom"
        >立 即 申 请</van-button
      >
      <van-button v-if="chakanshow" round type="info" @click="shenqing"
        >申 请 列 表</van-button
      >
    </div>
  </div>
</template>
<script>
import { getToken, setToken, getaccToken, setaccToken } from "@/utils/auth";
import { JS_ReDeliver, WeGetJobDetail } from "@/api/ResumeJS";
import { Dialog } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      search: "", //搜索
      list: {}, //列表
      jid: 0, //jid
      shenqingshow: true, //申请按钮
      chakanshow:false,
    };
  },
  created() {
    var token = getToken();
    var tokens = getaccToken();
    if (this.$route.query.state == 1) {
    } else {
      //   if (token == null) {
      //   this.$router.push({
      //     name: "recruitment",
      //   });
      // };
    }
    this.positionlist();
  },
  methods: {
    // 申请
    applyfrom() {
      var token = getToken();
      if (token == null) {
        this.$router.push({
          name: "recruitment",
        });
        return false;
      }
      JS_ReDeliver({ accToken: getToken(), JId: this.jid }).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "申请成功!",
          }).then(() => {
            // on close
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 查看申请列表
    shenqing() {
      this.$router.push({
        name: "applyfrom",
        query: { JId: this.jid },
      });
    },
    xiangqing(row) {
      this.$router.push({
        name: "corporate",
        query: { eId: row },
      });
    },
    // 获取职位详情
    positionlist() {
      WeGetJobDetail({ JId: this.$route.params.Id }).then((res) => {
        this.list = res.data.data;
        if (this.$route.query.state == 1) {
          this.shenqingshow = false;
          this.chakanshow = true;
        }
        this.jid = this.list.JId;
      });
    },
  },
};
</script>
<style scoped>
.recruitmentlist {
}
.limls .limlstitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.limls .limlstitle span:nth-child(1) {
  font-size: 22px;
  /* font-weight: 700; */
}
.limls .limlstitle span:nth-child(2) {
  padding-right: 20px;
  font-size: 18px;
  color: red;
}
.limls .limlsone {
  margin: 0px 0px 5px 15px;
  font-size: 15px;
  color: #444;
}
.limlsthree {
  margin: 15px;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.limlsthree div:nth-of-type(1) {
  font-size: 16px;
}
.limlsthree div:nth-of-type(2) {
  font-size: 12px;
  padding: 8px 0px;
}
.limlsthree span:nth-of-type(1) {
  font-size: 12px;
}
.limlsfour div {
  padding: 15px 0px 15px 15px;
  font-size: 16px;
  color: #333;
  font-weight: 700;
}
.limlsfour div:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  line-height: 25px;
}
.limlsfour div p {
  margin: 3px;
}
.button {
  position: fixed;
  left: 5%;
  bottom: 15px;
  width: 90%;
}
.button button {
  width: 100%;
}
.iconimg img {
  width: 4%;
}
</style>